import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { PATHS } from './core/shared/paths';

export const appRoutes: Route[] = [
	{ path: '', pathMatch: 'full', redirectTo: 'home' },
	{
		path: '',
		canActivate: [NoAuthGuard],
		canActivateChild: [NoAuthGuard],
		component: LayoutComponent,
		data: {
			layout: 'empty'
		},
		children: [
			{
				path: '',
				loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')
			},
			{
				path: 'confirmation-required',
				loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')
			},
			{
				path: 'forgot-password',
				loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')
			},
			{
				path: 'reset-password',
				loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')
			},
			{
				path: 'sign-in',
				loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')
			},
			{
				path: 'sign-up',
				loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')
			}
		]
	},

	{
		path: '',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		component: LayoutComponent,
		resolve: {
			initialData: initialDataResolver
		},
		children: [
			{
				path: 'home',
				loadChildren: () => import('app/modules/landing/home/home.routes')
			},
			{
				path: 'companies',
				loadChildren: () => import('app/modules/admin/companies/companies.module').then((m) => m.CompaniesModule)
			},
			{
				path: 'users',
				loadChildren: () => import('app/modules/admin/users/users.module').then((m) => m.UsersModule)
			},
			{
				path: 'popups',
				loadChildren: () => import('app/modules/admin/popups/popups.module').then((m) => m.PopupsModule)
			},
			{
				path: 'agencie',
				loadChildren: () => import('app/modules/admin/agencie/agencie.module').then((m) => m.AgencieModule)
			},
			/*{
				path: PATHS.maintenance.index,
				loadChildren: () => import('app/modules/admin/maintenance/maintenance.routes').then((m) => m.MaintenanceRoutingModule)
			},*/
			{
				path: 'promotion',
				loadChildren: () => import('app/modules/admin/promotion/promotion.module').then((m) => m.PromotionModule)
			},
			{
				path: 'applications',
				loadChildren: () => import('app/modules/admin/application/application.module').then((m) => m.ApplicationModule)
			},
			{
				path: 'actions',
				loadChildren: () => import('app/modules/admin/actions/actions.module').then((m) => m.ActionsModule)
			},
			{
				path: 'option',
				loadChildren: () => import('app/modules/admin/option/option.module').then((m) => m.OptionModule)
			},
			{
				path: 'rol',
				loadChildren: () => import('app/modules/admin/rol/rol.module').then((m) => m.RolModule)
			},
			{
				path: 'frontpage',
				loadChildren: () => import('app/modules/admin/frontpage/frontpage.module').then((m) => m.FrontPageModule)
			},
			/*{
				path: 'contacts',
				loadChildren: () => import('app/modules/admin/contacts/contacts.module').then((m) => m.ContactsModule)
			},*/
			{
				path: 'contactstemp',
				loadChildren: () => import('app/modules/admin/contacttemp/contacttemp.module').then((m) => m.ContacttempModule)
			},
			{
				path: 'permission',
				loadChildren: () => import('app/modules/admin/permission/permission.module').then((m) => m.PermissionModule)
			},
			{
				path: 'destination',
				loadChildren: () => import('app/modules/admin/destination/destination.module').then((m) => m.DestinationModule)
			},
			{
				path: 'airline',
				loadChildren: () => import('app/modules/admin/airline/airline.module').then((m) => m.AirlineModule)
			},
			{
				path: 'gallerie',
				loadChildren: () => import('app/modules/admin/gallerie/gallerie.module').then((m) => m.GallerieModule)
			},
			{
				path: 'touristPackage',
				loadChildren: () => import('app/modules/admin/touristPackage/touristPackage.module').then((m) => m.TouristPackageModule)
			},
			{
				path: 'theme',
				loadChildren: () => import('app/modules/admin/theme/theme.module').then((m) => m.ThemeModule)
			},
			{
				path: 'menupromotion',
				loadChildren: () => import('app/modules/admin/menupromotion/menupromotion.module').then((m) => m.MenuPromotionModule)
			},
			{
				path: 'shortUrl',
				loadChildren: () => import('app/modules/admin/shortUrl/shortUrl.module').then((m) => m.ShortUrlModule)
			},
			{
				path: 'travelOutlet',
				loadChildren: () => import('app/modules/admin/traveloutlet/traveloutlet.module').then((m) => m.TraveloutletModule)
			},
			{
				path: 'translation',
				loadChildren: () => import('app/modules/admin/translation/translation.module').then((m) => m.TranslationModule)
			},
			{
				path: 'agencie',
				loadChildren: () => import('app/modules/admin/agencie/agencie.module').then((m) => m.AgencieModule)
			},
			{
				path: 'booking',
				loadChildren: () => import('app/modules/admin/booking/booking.module').then((m) => m.BookingModule)
			},
			{
				path: 'flightBooking',
				loadChildren: () => import('app/modules/admin/flightBooking/flightBooking.module').then((m) => m.FlightBookingModule)
			},
			{
				path: 'flightcustomers',
				loadChildren: () => import('app/modules/admin/flightcustomers/flightcustomers.module').then((m) => m.FlightcustomersModule)
			},
			{
				path: 'override',
				loadChildren: () => import('app/modules/admin/override/override.module').then((m) => m.OverrideModule)
			},
			{
				path: 'paymentOrder',
				loadChildren: () => import('app/modules/admin/paymentOrder/paymentOrder.module').then((m) => m.PaymentOrderModule)
			},
			{
				path: 'configuration',
				loadChildren: () =>
					import('app/modules/admin/configuration/configurationPage/configurationPage.module').then((m) => m.ConfigurationPageModule)
			},
			{
				path: 'configurationAgil',
				loadChildren: () =>
					import('app/modules/admin/configurationAgil/configurationAgil.module').then((m) => m.ConfigurationAgilModule)
			},
			{
				path: 'configurationnm',
				loadChildren: () =>
					import('app/modules/admin/configurationNm/configurationNm.module').then((m) => m.ConfigurationNmModule)
			},
			{
				path: 'reportAuditoriaAccesos',
				loadChildren: () =>
					import('app/modules/admin/reportsAgil/reports-agil.module').then((m) => m.ReportsAgilModule)
			},
			{
				path: 'walletagil',
				loadChildren: () =>
					import('app/modules/admin/walletAgil/wallet-agil.module').then((m) => m.WalletAgilModule)
			},
			{
				path: 'flightReserve',
				loadChildren: () => import('app/modules/admin/flightReserve/flightReserve.module').then((m) => m.FlightReserveModule)
			},
			{
				path: 'reglasFakeName',
				loadChildren: () => import('app/modules/admin/rulesFakeName/rulesFakeName.module').then((m) => m.RulesFakeNameModule)
			},
			{
				path: 'ticketNdc',
				loadChildren: () => import('app/modules/admin/ticketNdc/ticketNdc.module').then((m) => m.TicketNdcModule)
			},
			{
				path: 'advancepurchases',
				loadChildren: () => import('app/modules/admin/advancepurchases/advancepurchases.module').then((m) => m.AdvancepurchasesModule)
			},
			{
				path: 'generatePayment',
				loadChildren: () => import('app/modules/admin/generatePayment/generate-payment.module').then((m) => m.GeneratePaymentModule)
			},
			{
				path: 'queueEmission',
				loadChildren: () => import('app/modules/admin/queueEmission/queueEmission.module').then((m) => m.QueueEmissionModule)
			},
			{
				path: 'wedding',
				loadChildren: () => import('app/modules/admin/configurationAgil/configuration-romance/configurationRomance.module').then((m) => m.ConfigurationRomanceModule)
			},
			{
				path: 'exchangerate',
				loadChildren: () => import('app/modules/admin/changerate/changerate.module').then((m) => m.ChangerateModule)
			},
			{
				path: 'city',
				loadChildren: () => import('app/modules/admin/city/city.module').then((m) => m.CityModule)
			},
			{
				path: 'flightConfiguration',
				loadChildren: () => import('app/modules/admin/flightConfiguration/flightConfiguration.module').then((m) => m.FlightConfigurationModule)
			},
			{
				path: 'subRuler',
				loadChildren: () => import('app/modules/admin/flightConfiguration/SubRuler/subRuler.module').then((m) => m.SubRulerModule)
			},
			{
				path: 'multiTicket',
				loadChildren: () => import('app/modules/admin/flightConfiguration/MultiTicket/multiTicket.module').then((m) => m.MultiTicketModule)
			},
			{
				path: 'ticket',
				loadChildren: () => import('app/modules/admin/ticket/ticket.module').then((m) => m.TicketModule)
			}

		]
	}
];
