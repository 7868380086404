export const environment = {
	production: true,
	headerValue: '74939ae2958a4018818f6365fb2b0670',
	msalConfig: {
		auth: {
			clientId: '01e910ac-55e4-46e0-a975-84ffe8f91d38',
			tenantId: 'f47010b4-09cd-41ff-a9ca-7e9b411249f6',
			redirectUri: 'https://sat-dev.nmviajes-it.com'
		},
		apiConfig: {
			oneConfig: {
				scopes: ['user.Read'],
				uri: 'https://graph.microsoft.com/v1.0/me'
			},
			twoConfig: {
				scopes: ['api://aff2b74e-7317-453d-9e79-abdec655bc2a/api.scope'],
				uri: 'https://localhost:44370'
			}
		}
	},
	securityKey: '484060556f8744ab799c69961bc5a6c190f22097',

	urlIdentityApi: 'https://sat-dev.nmviajes-it.com/identity/api/v1',
	urlAgilApi: 'https://agilservice-dev.nmviajes-it.com/APIServiceAgil/v1/api',
	urlRulesFakeName: 'https://dev-motorvuelos.nmviajes-it.com/reglas/v1/api',
	urlRule: 'https://dev-motorvuelos.nmviajes-it.com/reglas/v1/api',
	urlNMViajesApi: 'https://sat-dev.nmviajes-it.com/nmviajes/api/v1',
	urlFlighEngineApi: 'https://sat-dev.nmviajes-it.com/flightengine/api/v1',
	urlGeoLocate: 'https://ipapi.co/json/',

	urlTravelOulet: 'https://serviciodev.nmviajes.com:9443/NMForms/api',

	urlTravelCApi: 'https://sat-motores.nmviajes-it.com:9443/v1/api/Booking',
    urlMotorVueloApi: 'https://sat-vuelos.nmviajes-it.com:9444/v1/api/Booking',
	urlMotorVueloApiNdc:'https://sat-vuelos.nmviajes-it.com:9444/',
	urlMotorEmisionApi: 'https://sat-emisiones.nmviajes-it.com:9445/v1/api/Emision',
	urlMotorEmisionApiGatewayColas: 'https://agilservice-dev.nmviajes-it.com:6443',
	urlMotorEmisionApiGatewayColasEmail: 'https://agilservice-dev.nmviajes-it.com/ApiColaGateway/reportes',

	urlConfiguration: 'https://dev-motorvuelos.nmviajes-it.com/reglas/v1/api',

	urlAgilSeguridadApi: 'https://agilservice-dev.nmviajes-it.com/APIServiceAgilSeguridad/v1/api',
	urlAgilAgenciesApi: 'https://agilservice-dev.nmviajes-it.com/APIServiceAgil/v1/api',
	urlAgilConfigurationApi: 'https://agilservice-dev.nmviajes-it.com/APIServiceAgilConfiguracion/v1/api',

	urlMotorEmisionApiGatewayColasEmailTicket: 'https://agilservice-dev.nmviajes-it.com/ApiColaGateway/emisiones',
	urlNmConfigurationApi: 'https://serviciodev.nmviajes.com:9443/NMConfiguracionesAPI/v1/api',
	urlNmbffApi: 'https://dev-motorvuelos.nmviajes-it.com/mv',


	urlMotorVueloApiGeneral: 'https://sat-vuelos.nmviajes-it.com:9444/v1/api',

	urlAgilSoapConfigurationApi: 'https://agilservice-dev.nmviajes-it.com:6443/APIServiceAgilSoap/v1/api',

    urlChangeRate: 'https://agilservice-dev.nmviajes-it.com:6443/APIExpertiaMotorVuelosNEW/v1/api',
	urlQueue: 'https://agilservice-dev.nmviajes-it.com/ApiColaGateway',
	urlSatFlighEngineApi: 'https://sat-dev.nmviajes-it.com/FlightEngine/api/v1'
};

