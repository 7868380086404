import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation, Option } from 'app/core/navigation/navigation.types';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'classy-layout',
	templateUrl: './classy.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		FuseLoadingBarComponent,
		FuseVerticalNavigationComponent,
		NotificationsComponent,
		UserComponent,
		NgIf,
		MatIconModule,
		MatButtonModule,
		LanguagesComponent,
		FuseFullscreenComponent,
		SearchComponent,
		ShortcutsComponent,
		MessagesComponent,
		RouterOutlet,
		QuickChatComponent,
		CommonModule
	]
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
	isScreenSmall: boolean;
	navigation: Navigation;
	options: Option[];
	user: User;
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	/**
	 * Constructor
	 */
	constructor(
		private _activatedRoute: ActivatedRoute,
		private _router: Router,
		private _navigationService: NavigationService,
		private _userService: UserService,
		private _fuseMediaWatcherService: FuseMediaWatcherService,
		private _fuseNavigationService: FuseNavigationService
	) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Getter for current year
	 */
	get currentYear(): number {
		return new Date().getFullYear();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Subscribe to navigation data
		/*this._navigationService.navigation$.pipe(takeUntil(this._unsubscribeAll)).subscribe((navigation: Navigation) => {
			this.navigation = navigation;
		});*/

		this.navigation = { compact: [], default: [], futuristic: [], horizontal: [] };
		this.options = JSON.parse(localStorage.getItem('Menu'));
		//this.applications = JSON.parse(localStorage.getItem('Application'));
		//const result = this.options.filter( x => x.parentId === 0 && x.type === 'm');

		/*this.applications.forEach(element => {
			this.navigation.default.push(
				{
					id: element.id.toString(),
					title: element.name,
					subtitle: element.description,
					type: 'group',
					icon: String(element.logo).replace('.',':')
				}
			);
		});*/

		this.options
			.filter((x) => x.parentId === 0 && x.type === 'm')
			.forEach((element) => {
				this.navigation.default.push({
					id: element.id.toString(),
					title: element.name,
					subtitle: element.description,
					type: 'group',
					icon: String(element.icon).replace('.', ':')
				});
			});

		this.navigation.default.forEach((element) => {
			element.children = [];
			this.options
				.filter((x) => x.parentId.toString() === element.id && x.type === 'm')
				.forEach((elem) => {
					element.children.push({
						id: elem.id.toString(),
						title: elem.name,
						type: 'basic',
						icon: String(elem.icon).replace('.', ':'),
						link: elem.url
					});
				});
		});

		// Subscribe to the user service
		this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
			this.user = user;
		});

		// Subscribe to media changes
		this._fuseMediaWatcherService.onMediaChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(({ matchingAliases }) => {
			// Check if the screen is small
			this.isScreenSmall = !matchingAliases.includes('md');
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle navigation
	 *
	 * @param name
	 */
	toggleNavigation(name: string): void {
		// Get the navigation
		const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

		if (navigation) {
			// Toggle the opened status
			navigation.toggle();
		}
	}
}
