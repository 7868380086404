import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHandlerFn, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { FormBuilder, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { EMPTY, Observable, catchError } from "rxjs";


export const errorInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  let configForm: UntypedFormGroup;
  const router = inject(Router);
  const fb = inject(FormBuilder);
  const fcs = inject(FuseConfirmationService);

  return next(req).pipe(
    catchError((response) => {
      console.log('intercept|response: ', response);
      if (response instanceof HttpErrorResponse) {
        //console.log('intercept ', response.status);
        fcs.closeAll();
        if (response.status >= 300 && response.status <= 399) {
          router.navigate(['404-not-found']);
        }
        if (response.status == 429) {
          fb.group({
            title: 'Advertencia',
            message: 'La cantidad de solicitudes ha excedido lo permitido.',
            icon: fb.group({
              name: 'heroicons_outline:exclamation',
              color: 'warn'
            }),
            actions: fb.group({
              confirm: fb.group({
                show: true,
                label: 'Aceptar',
                color: 'warn'
              }),
              cancel: fb.group({
                show: false,
                label: 'Cancelar'
              })
            }),
            dismissible: true
          });
          const dialogRef = fcs.open(configForm.value);
        }

        if ((response.status === 419)) {
          // Build the config form
          configForm = fb.group({
            title: 'Advertencia',
            message: 'No tiene el permiso para realizar esta acción.',
            icon: fb.group({
              show: true,
              name: 'heroicons_outline:exclamation',
              color: 'warn'
            }),
            actions: fb.group({
              confirm: fb.group({
                show: true,
                label: 'Aceptar',
                color: 'warn'
              }),
              cancel: fb.group({
                show: false,
                label: 'Cancelar'
              })
            }),
            dismissible: true
          });

          // Open the dialog and save the reference of it
          const dialogRef = fcs.open(configForm.value);

          //this.router.navigate(['401-unauthorized']);
        }

        if (response.status === 401){
          //const smsError = response.error.errors.map(item => {item.message});
          router.navigate([''],{replaceUrl: true});
          fcs.open({
            title: 'Información',
            message: 'No autorizado',
            icon: {
              show: true,
              name: 'heroicons_outline:exclamation-triangle',
              color: 'warning'
            },
            actions: {
              confirm: {
                show: true,
                label: 'Aceptar',
                color: 'primary'
              },
              cancel: {
                show: false
              }
            },
            dismissible: false
          });
          
          //router.navigate(['session-expired']);
        }

        if (response.status === 400 || response.status === 404 || response.status === 500 || response.status === 409) {
          let listError = "";
          response.error.map(item => { listError += `${item.message}` + "<br>"; })
          fcs.open({
            title: 'Información',
            message: listError,
            icon: {
              show: true,
              name: 'heroicons_outline:exclamation-triangle',
              color: 'info'
            },
            actions: {
              confirm: {
                show: true,
                label: 'Aceptar',
                color: 'primary',
              },
              cancel: {
                show: false,
                label: 'Cancelar',
              },
            },
            dismissible: false,
          })
        };
      }
      console.log('empty');
      return EMPTY;
    })
  );
}