/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
	{
		id: 'administracion',
		title: 'Administración',
		subtitle: 'Sistema de Administración de Turismo',
		type: 'group',
		icon: 'heroicons_outline:home',
		children: [
			{
				id: 'administracion.companias',
				title: 'Compañias',
				type: 'basic',
				icon: 'heroicons_outline:building-office-2',
				link: '/maintenance/companies'
			},
			{
				id: 'administracion.aplicaciones',
				title: 'Aplicaciones',
				type: 'basic',
				icon: 'heroicons_outline:square-3-stack-3d',
				link: '/applications'
			},
			{
				id: 'administracion.roles',
				title: 'Roles',
				type: 'basic',
				icon: 'heroicons_outline:user-group',
				link: '/rol/list-rol'
			},
			{
				id: 'administracion.usuarios',
				title: 'Usuarios',
				type: 'basic',
				icon: 'heroicons_outline:users',
				link: '/maintenance/users'
			},
			{
				id: 'administracion.opciones',
				title: 'Opciones',
				type: 'basic',
				icon: 'heroicons_outline:bars-3',
				link: '/option/list-option'
			},
			{
				id: 'administracion.acciones',
				title: 'Acciones',
				type: 'basic',
				icon: 'heroicons_outline:wrench',
				link: '/actions'
			},
			{
				id: 'administracion.permisos',
				title: 'Permisos',
				type: 'basic',
				icon: 'heroicons_outline:finger-print',
				link: '/permission/list-permission'
			}
		]
	},
	{
		id: 'agil',
		title: 'Web Ágil',
		subtitle: 'Sistema de venta para agencias B2B',
		type: 'group',
		icon: 'heroicons_outline:home',
		children: [
			{
				id: 'agil.logos',
				title: 'Logos para agencias',
				type: 'basic',
				icon: 'heroicons_outline:paint-brush',
				link: '#'
			},
			{
				id: 'agil.popups',
				title: 'Popups informativos',
				type: 'basic',
				icon: 'heroicons_outline:window',
				link: '/maintenance/popups'
			},
			{
				id: 'agil.promociones',
				title: 'Promociones',
				type: 'basic',
				icon: 'heroicons_outline:megaphone',
				link: '/frontpage'
			},
			{
				id: 'agil.contactos',
				title: 'Contactos',
				type: 'basic',
				icon: 'heroicons_outline:identification',
				link: '/contacts'
			}
		]
	},
	{
		id: 'nmviajes',
		title: 'NMViajes',
		subtitle: 'Sistema e-commerce B2C',
		type: 'group',
		icon: 'heroicons_outline:home',
		children: [
			{
				id: 'nmviajes.images',
				title: 'Imágenes',
				type: 'basic',
				icon: 'heroicons_outline:camera',
				link: '/gallerie/list-gallerie'
			},
			{
				id: 'nmviajes.aerolineas',
				title: 'Aerolineas',
				type: 'basic',
				icon: 'heroicons_outline:paper-airplane',
				link: '/airline/list-airline'
			},
			{
				id: 'nmviajes.destinos',
				title: 'Destinos',
				type: 'basic',
				icon: 'heroicons_outline:map-pin',
				link: '/destination/list-destination'
			}
			
		]
	}
];
export const compactNavigation: FuseNavigationItem[] = [
	{
		id: 'example',
		title: 'Example',
		type: 'basic',
		icon: 'heroicons_outline:chart-pie',
		link: '/example'
	}
];
export const futuristicNavigation: FuseNavigationItem[] = [
	{
		id: 'example',
		title: 'Example',
		type: 'basic',
		icon: 'heroicons_outline:chart-pie',
		link: '/example'
	}
];
export const horizontalNavigation: FuseNavigationItem[] = [
	{
		id: 'example',
		title: 'Example',
		type: 'basic',
		icon: 'heroicons_outline:chart-pie',
		link: '/example'
	}
];
