import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { CryptoService } from '../shared/common/services/crypto.service';
import { AuthEndpoint } from '../../providers/endpoints/auth.enpoint';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { Gelocalization, User } from '../user/user.types';
 
@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;
    private user: User;
    private geolocalization: Gelocalization;
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _userService: UserService, private _cryptoService: CryptoService) {}
 
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
 
    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('Token', token);
    }
 
    get accessToken(): string {
        return localStorage.getItem('Token') ?? '';
    }
 
    /**
     * Setter & getter for access menu
     */
    set accessMenu(menu: string) {
        localStorage.setItem('Menu', menu);
    }
 
    get accessMenu(): string {
        return localStorage.getItem('Menu') ?? '';
    }
 
    /**
     * Setter & getter for access Aplication
     */
    set accessApplication(application: string) {
        localStorage.setItem('Application', application);
    }
 
    get accessApplication(): string {
        return localStorage.getItem('Application') ?? '';
    }
 
    /**
     * Setter & getter for access Aplication
     */
    set accessRoles(roles: string) {
        localStorage.setItem('Roles', roles);
    }
 
    get accessRoles(): string {
        return localStorage.getItem('Roles') ?? '';
    }
    /**
    /**
    * Setter & getter for access Aplication
    */
    set accessPermissions(roles: string) {
        localStorage.setItem('Permissions', roles);
    }
 
    get accessPermissions(): string {
        return localStorage.getItem('Permissions') ?? '';
    }
 
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
 
    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }
 
    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }
 
    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { userName: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            localStorage.clear();
            return throwError('User is already logged in.');
        }
 
        return this._httpClient.post(AuthEndpoint.login, credentials).pipe(
            catchError((error) => {
                localStorage.clear();
                console.error('Error in HTTP request:', error);
                return throwError(error);
            }),
            switchMap((response: any) => {
                try {
                    //const result = JSON.parse(this._cryptoService.decrypt(response.response.data));
 
                    // Store the access token in the local storage
                    this.accessToken = response.response.data.token;
 
                    // Store the access Menu in the local storage
                    this.accessMenu = JSON.stringify(response.response.data.page);
 
                    // Store the access Application in the local storage
                    this.accessApplication = JSON.stringify(response.response.data.application);
 
                    // Store the access Roles in the local storage
                    this.accessRoles = JSON.stringify(response.response.data.roles);
 
                    // Store the access Permissions in the local storage
                    this.accessPermissions = JSON.stringify(response.response.data.permissions);
 
                    // Set the authenticated flag to true
                    this._authenticated = true;
 
                    // Store the user on the user service
                    this._userService.user = response.response.data.user;
 					this._userService.application = response.response.data.application;

                    this._userService.getGeo().subscribe();
 
                    // Return a new observable with the response
                    return of(response.response);
                } catch (error) {
                    console.error('Error in switchMap:', error);
                    return throwError(error);
                }
            })
        );
    }
 
    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        return this._httpClient
            .post(AuthEndpoint.loginToken, {
                token: this.accessToken
            })
            .pipe(
                catchError(() => {
                    localStorage.clear();
                    return of(false);
                }),
                switchMap((response: any) => {
                    if (response.response.data.token) {
                        this.accessToken = response.response.data.token;
                    }
 
                    this._authenticated = true;
 
                    this._userService.user = response.response.data.user;
					this._userService.application = response.response.data.application;
                    this._userService.getGeo().subscribe();
 
                    return of(true);
                })
            );
    }
 
    signInSsoToken(obj: any): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            localStorage.clear();
            return throwError('User is already logged in.');
        }
        const uri = AuthEndpoint.loginSsoToken;
        const testToken = { Token: obj.idToken };
        return this._httpClient.post(uri, testToken).pipe(
            catchError((error) => {
                localStorage.clear();
                console.error('Error in HTTP request:', error);
                return throwError(error);
            }),
            switchMap((response: any) => {
                try {
                    //const result = JSON.parse(this._cryptoService.decrypt(response.response.data));
 
                    // Store the access token in the local storage
                    this.accessToken = response.response.data.token;
 
                    // Store the access Menu in the local storage
                    this.accessMenu = JSON.stringify(response.response.data.page);
 
                    // Store the access Application in the local storage
                    this.accessApplication = JSON.stringify(response.response.data.application);
 
                    // Store the access Roles in the local storage
                    this.accessRoles = JSON.stringify(response.response.data.roles);
 
                    // Store the access Permissions in the local storage
                    this.accessPermissions = JSON.stringify(response.response.data.permissions);
 
                    // Set the authenticated flag to true
                    this._authenticated = true;
 
                    // Store the user on the user service
                    this._userService.user = response.response.data.user;
 					this._userService.application = response.response.data.application;

                    this._userService.getGeo().subscribe();
 
                    // Return a new observable with the response
                    return of(response.response);
                } catch (error) {
                    console.error('Error in switchMap:', error);
                    return throwError(error);
                }
            })
        );
    }
 
    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.clear();
 
        // Set the authenticated flag to false
        this._authenticated = false;
 
        // Return the observable
        return of(true);
    }
 
    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }
 
    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }
 
    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }
 
        // Check the access token availability
        if (!this.accessToken) {
            localStorage.clear();
            return of(false);
        }
 
        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            localStorage.clear();
            return of(false);
        }
 
        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}